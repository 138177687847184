.wrapper {
    display: grid;
}

img {
    display: block;
    max-width: 100%;
    grid-area: 1/1;
}

.skeleton {
    width: 100%;
    grid-area: 1/1;
    height: 100%;
    max-height: 400px;
    background: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
    background-color: #eee;
    background-size: 200%;
    animation: skeleton 1.5s infinite linear;
}

@keyframes skeleton {
    from {
        background-position: 0px;
    } to {
        background-position: -200%;
    }
}