@import '../utils/variables.scss';

.user {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5rem;
    color: var(--color-200);
    font-weight: 500;

    background-color: transparent;
    border: 0;

    > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 3rem;
    }
}
@media (max-width: 800px) {
    .user {
        justify-content: start;
    }
}