.memePerfil {
    width: 300px;
    height: 300px;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0 10 rgba($color: #000000, $alpha: 0.6);
    overflow: hidden;

    &__data {
        width: 100%;
        height: 100%;
        transition: 0.2s;
        display: none;
        place-items: center;
        color: #fff;

        &-icons {
            display: flex;
            gap: 2rem;

            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                    font-size: 1.5rem;
                }
            }
        }
    }
    
    &:hover &__data {
        display: grid;
        background-color: rgba($color: #000000, $alpha: 0.6);

    }

    img {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
    }
}