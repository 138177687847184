footer {
    padding: 2.5rem;
    width: 100%;
    display: grid;
    place-items: center;
    background-color: var(--primary);
    box-shadow: 0px 0px 10px solid ;

    ul {
        list-style: none;
        margin-top: 1rem;
    }
    
    a {
        color: var(--color-200);
        font-size: 1rem;

        & + a {
            margin-left: 1rem;
        }

        transition: .2s;

        &:hover {
            filter: brightness(.8);
        }
    }
}