@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin  center-al-c {
    display: flex;
    align-items: center;
}

@mixin btn-primary {
    cursor: pointer;
    padding: .3rem .7rem;
    background-color: var(--primary);
    color: #494949;
    font-weight: 500;

    border: 0;
    border-radius: .5rem;

    transition: .2s;

    &:disabled {
        background-color: var(--secondary);
        color: $color-dark-white;
    }

    &:not(:disabled) {
        &:hover {
            background-color: $color-dark-white;
            box-shadow: 0 0 0 2px var(--primary) ;
        }
    }
}

@mixin input {
    width: 100%;
    height: 50px;
    padding: 0 1rem;
    border-radius: 4px;
    border: 1px solid var(--color-200);
    background-color: var(--background-200);

    &:focus {
        outline-width: var(--primary);
    }
}

@mixin hover {
    transition: .2s;
    &:hover {
        filter: brightness(.8);
    }
}