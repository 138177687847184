@import '../utils/mixins.scss';
@import '../utils/variables.scss';


.sing {
    height: 100vh;
    display: flex;

     input {
        @include input;
    }

    &__aside {
        flex: 6;
        background-color: var(--primary);
        @include center;

        img {
            max-width: 420px;
        }
    }

    &__main {
        padding: 0 5%;
        flex: 8;

        @include center;
    }

    .container_sing {
        background-color: var(--background);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;


        padding: 2rem;
        max-width: 350px;
        width: 100%;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
        border-radius: 8px;

        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        button {
            width: 100%;
        }

        .logo {
            height: 70px;
            margin-bottom: 10px;
        }

        .separator {
            display: flex;
            align-items: center;
            width: 100%;
            // margin: 0 16px;

            &::after, &::before {
                content: '';
                height: 1px;
                background-color: var(--color-200);
                flex: 1;
            }

            &::after {
                margin-left: 1rem;
            }

            &::before {
                margin-right: 1rem;
            }
        }

        a {
            color: var(--color-200);
            font-weight: 500;
        }
    }
}


@media (max-width: 810px) {
    .sing {
        &__aside {
            display: none;
        }

        &__main {
            background-image: url(../../assets/image/login.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-color: var(--primary);
            background-size: cover;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fac563b2;
                width: 100vw;
                height: 100vh;
                z-index: 1;
            }
        }

        .container_sing {
            z-index: 2;
            
        }
    }
}