@import '../utils/mixins.scss';
@import '../utils/variables.scss';

.perfil {
    min-height: calc(100vh - 8rem);
    background-color: $color-dark-white;
    
    &__info {
        margin: 32px 0 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; 
        flex-direction: column;
        border-bottom: 1px solid var(--secondary-300);
        padding-bottom: 1rem;

        .container__img-perfil {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            overflow: hidden;

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


        &-conquest {
            display: flex;
            gap: 1rem;
            font-weight: 700;
            color: var(--secondary);
        }
    }

    &__memes {
        max-width: 1200px;
        padding: 0 5%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        margin-bottom: 32px;
        gap: 2rem;        
    }
}

@media (max-width: 1090px) {
    .perfil {
        &__memes {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 680px) {
    .perfil {
        &__memes {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}