@import '../utils/variables.scss';
@import '../utils/mixins.scss';

@mixin btnOptionsCard {
    color: var(--color-200);
    display: flex;
    align-items: center;
    gap: .3rem;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    height: 24px;
    font-size: 24px;
    transition: .2s;

    &:hover {
        filter: brightness(.9);
    }
}

.meme {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: $box-shadow;
    border: 2px solid var(--primary);
    background-color: var(--background);

    &__header{
        position: relative;
        padding: .5rem 1rem;
        @include center-al-c;
        justify-content: space-between;
        span {
            font-size: 1.1rem;
            font-weight: 500;
        }

        &_btnopenmodal {
            @include btnOptionsCard;
        }

        &_modal {
            display: none;

            &-btndelete {
                color: #ff063eb8 !important;
            }
        }

        &_modal.active {
            animation: animaup .2s;
            display: inline-block;
            position: absolute;
            background-color: #fff;
            right: 5px;
            top: 3rem;
            box-shadow: $box-shadow;
            font-size: 1rem;
            border-radius: .5rem;
            overflow: hidden;
            
            a, button {
                background-color: #fff;
                display: block;
                width: 100%;
                padding: 0.7rem 1.5rem;
                color: var(--color-200);
                text-decoration: none;
                transition: .2s;
                cursor: pointer;

                &:hover {
                    filter: brightness(.9);
                }
            }

            button {
                @include center-al-c;
                gap: 1rem;
                border: 0;
            }
        }

        img {
            width: 30px;
            height: 30px;
        }
    }

    &__img {
        width: 100%;
        object-fit: cover
    }

    .meme__footer {
        padding: .7rem .7rem 0;
    }

    &__btn {
    
        display: flex;
        justify-content:space-between;
        gap: 1rem;

        button {
            @include btnOptionsCard;

            span {
                font-size: 18px;
                height: 22px;
            }


            
            &:disabled{
                cursor: not-allowed;
            }
            &.isLiked {
                color: var(--primary);
            }
        }
    }

    &__input {
        display: flex;
        margin-top: 1rem;

        input {
            flex: 4;
            height: 40px;
            border: 1px solid $color-gray-ligth;
            border-radius: 8px 0 0 8px;
            padding: 0 1rem;
        }
        button {
            flex: 1;
            @include btn-primary;
            
            border-radius:  0 8px 8px 0;
        }
    }

    .container-comments {
        max-height: 10rem;
        overflow-y: auto;
        margin: .2rem .7rem;

        .btn-view-comment {
            @include btn-primary;
        }

        .comment {
            margin-top: .5rem;
            border-bottom: 1px solid var(--background-200);

            &__user {
                display: flex;
                align-items: center;
                justify-content: space-between;

                article {
                    width: 100%;
                    @include center-al-c;
                    gap: .7rem;
                    font-size: 14px;
    
                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                        border-radius: 100%;
                    }
    
                }

                button {
                    @include btnOptionsCard;
                }
            }

            
            &:not(:last-child) {
                margin-bottom: .3rem;
            }
            &__author {
                font-size: 14px;
            }
            .meme-content {
                margin-left: 35px;
                font-size: 15px;
                font-weight: 700;
                color: var(--secondary)
            }
        }
    }
}

@keyframes animaup {
    from {
        top: 1rem;
    } to {
        top: 3rem;
    }
}