@import '../utils/mixins.scss';
@import '../utils/variables.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5%;
    background-color: var(--background);
    border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
    box-sizing: border-box;
    height: 90px;

    .btn-memu {
        cursor: pointer;
        background-color: var(--primary);
        color: var(--color-200);
        height: 50px;
        padding: 0 32px;
        border-radius: 8px;
        border: none;
        font-weight: 500;
        display: none;
    }

    &__logo {
        height: 50px;
        flex: 2;
        pointer-events: none;

        &:active {
            cursor: no-drop;
        }
    }

    nav {
        display: flex;
        align-items: center;
        gap: 2rem;

        
        .categories-header {
            display: none;
            list-style: none;
            cursor: pointer;
            position: relative;

            li {
                font-weight: 700;
                display: flex;
                align-items: center;
                color: var(--secondary);
            }

            .drop-down-categorias {
                display: none;
                position: absolute;
                background-color: rgba($color: var(--secondary), $alpha: .8);
                border-radius: 8px;
                width: 200px;
                transform: translateY(2rem);
                left: 50%;
                transform: translateX( -50%);

                li {
                    font-weight: 500;
                    color: #f8f8f8;
                    padding: .5rem;

                    &:hover {
                        background-color: rgba($color: var(--primary), $alpha: .5);
                    }
                }

                &.show {
                    display: block;
                    animation: animaLeft .5s forwards;                
                }
            }
            
        }

        .nav-btns {
            display: flex;
            align-items: center;
            gap: 2rem;
            position: relative;
            z-index: 5;

            .modal__perfil  {
                display: none;
            }
            
            .modal__perfil.active {
                position: absolute;
                z-index: 5;
                top: 50px;
                border-radius: 5px;
                box-shadow: 0 0 10px rgba($color: #000000, $alpha: .2);
                background-color: white;
                display: flex;
                flex-direction: column;
                width: 150px;
                animation: animaDown .2s;
                
                a {
                    font-size: 1rem;
                    color: var(--color-200);
                    display: flex;
                    align-items: center;
                    padding: .5rem 1rem;
                    gap: .2rem;
                    @include hover;
                }

                button {
                    display: flex;
                    align-items: center;
                    gap: .2rem;
                    text-align: left;
                    padding: .5rem 1rem;
                    border: 0;
                    background-color: transparent;
                    color: red;
                    cursor: pointer;
                    @include hover;
                }
            }
    
            .perfil {
                cursor: pointer;
                background-color: transparent;
                border: 0;
                border-radius: .2rem;
                padding: .2rem .5rem;
    
                &:hover {
                    background-color: #b6b6b671;
                }
            }
        }
    }

}

@media (max-width: 800px) {
    .header{
        .btn-memu {
            display: block;
        }

        nav {
            flex-direction: column;
            position: fixed;
            top: 0;
            bottom: 0;
            right: -100%;
            z-index: 3;
            max-width: 250px;
            width: 100%;
            background-color: var(--primary);
            padding:  2rem 2%;
            box-shadow: 0 0 10px rgba($color: var(--secondary), $alpha: .4);
            transition: .2s;
    
    
            button {
                width: 100%;
            }

            .categories-header {
                display: block ;
            }
    
            .user {
                padding: .2rem 0;
    
                font-weight: 700;
            }
    
            
            .nav-btns {
                width: 100%;
                flex: 1;
                flex-direction: column;
    
                
                .modal__perfil  {
                    display: none;
                }
                
                .modal__perfil.active {
                    position: static;
                    background-color:var(--secondary);
                    width: 100%;
                     a, button {
                        font-size: 1.2rem;
                     }
                    
                    a {
                        color: #fff;
                    }
                }

    
                .button {
                    width: 100%;
                    background-color:var(--secondary);
                    color: #f8f8f8;
                }
            }
        }
    
        nav.show {
            right: 0;
        }
    }
}

@keyframes animaLeft{
    to {
        // transform: translateX(10px);
        opacity: 1;
    } from {
        // transform: translateX(0);
        opacity: 0;
    }
}

@keyframes animaDown {
    from {
        transform: translateY(-10px);
        opacity: 0;
    } to {
        transform: translateY(0px);
        opacity: 1 ;
    }
}