@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.create-meme {
    padding: 2rem 5%;
    @include center;
    min-height: calc(100vh - 90px);
    flex-direction: column;
    
    
    h1 {
        margin-bottom: 40px;
    }

    input {
        height: 50px;
        padding: 0 1rem;
        border-radius: 4px;
        border: 1px solid gray;
    }
    
    &-container {
        display: flex;
        gap: 2rem;
        align-items: center;

        .container__preview {
            button {
                width: 100%;
                &:not(:last-child) {
                    margin-bottom: .5rem;
                }
            }
            &-btn {
                margin-top: 2rem;
            }

            .separator {
                display: flex;
                align-items: center;
                width: 100%;
                margin: 1rem 0;
    
                &::after, &::before {
                    content: '';
                    height: 1px;
                    background-color: var(--color-200);
                    flex: 1;
                }
    
                &::after {
                    margin-left: 1rem;
                }
    
                &::before {
                    margin-right: 1rem;
                }
            }

            &-send {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }

    }


    &__select {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > img {
            width: 300px;
            margin-top: 1rem;
            border-radius: 4px;
            box-shadow: 0 0 10px rgb(0, 0, 0, .2);
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &.form-invalid {
            input {
                border: 1px solid $color-error;
            }
        }

        label {
            display: block;
            font-weight: 700;
            color: var(--color-200);
        }

        
        .menssage-error-input{ 
            display: block;
            font-size: 10px;
            color: $color-error;
        }
    }
}


@media ( max-width: 800px) {
    .create-meme {
        &-container {
            flex-direction: column;
        }
    }
}